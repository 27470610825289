import { useContext, useEffect, useState } from 'react'
import { BiShare, BiUpload } from 'react-icons/bi'
import { useCache } from '../../CacheContext'
import axios from 'axios'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { loadStripe } from '@stripe/stripe-js'
import giftStory from '../../assets/images/gift-story.png'
import { AuthContext } from '../../context/AuthContext'
export default function GiftYourStory() {
  const [selectedStory, setSelectedStory] = useState(null)
  const [shippingMethod, setShippingMethod] = useState('shipping')
  const [imagePreview, setImagePreview] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const [address, setAddress] = useState({ street: '', city: '', state: '', postalCode: '' });
  const { setCacheData, getCacheData } = useCache();
  const [loading, setLoading] = useState(true);
  const [storyLoading, setStoryLoading] = useState(false);

  const {
    isAuthenticated
  } = useContext(AuthContext)
  const [stories, setStories] = useState(() => getCacheData("stories") || []);
  const [story, setStory] = useState(
      () => getCacheData(`giftStory_${selectedStory?.value}`) || null
    );
  useEffect(() => {
    if (!stories.length && isAuthenticated) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/story`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setStories(response.data.data);
          setCacheData("stories", response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message ||
            error.message ||
            "An unexpected error occurred while fetching the stories";
          toast.error(errorMessage);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [stories, setCacheData, isAuthenticated]);
  const fetchData = async (id) => {
    if(!id) return;
    setStoryLoading(true);
    setStory(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/story/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("single turn story api call made");
      setStory(response.data.data);
      setCacheData(`giftStory_${id}`, response.data.data);
      setStoryLoading(false);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred while fetching the story.";
      toast.error(errorMessage);
      setStoryLoading(false);
    }
  };
  const handleImageUpload = (e) => {
    const file = e.target.files?.[0]

    if (file) {
      setImageFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(selectedStory?.value);
    const formData = new FormData();
    formData.append('story', selectedStory?.value);
    formData.append('shippingMethod', shippingMethod);

    if (shippingMethod === 'shipping') {
      formData.append('street', address.street);
      formData.append('city', address.city);
      formData.append('state', address.state);
      formData.append('postalCode', address.postalCode);
      formData.append('price', 50);
    }else{
      formData.append('price', 40);
    }
    if (imagePreview) {
      formData.append('image', imageFile);
    }
  
    try {
      setLoading(true);
      
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY || "pk_live_51Nkh21R2FfpAxaQMZNMmELmm8XB9iw1ll1yQPI6ChCW0E8GWcnb6rGr8Zcy5dNAjljlJHm7DiU1uYfMx0Zfbo6ST00GeKHgC9y");

      

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/checkout/create-gift-checkout`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.success) {
        setLoading(false);
        stripe.redirectToCheckout({ sessionId: response.data.message });
        
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
      setLoading(false);
    }
  };
  const isFormValid = selectedStory && (shippingMethod === 'pickup' || (shippingMethod === 'shipping' && address.street && address.city && address.state && address.postalCode));
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 single-story">
      {/* Header Section */}
      <div className="text-center mb-12">
      <h3 className="text-center font-semibold text-gray-900 leading-normal">
      Turn Your Story into a gift
    </h3>
    <p>$40 (exc. shipping)</p>
        <p>The most meaningful gift is a story about them or a shared moment between you. It’s a one-of-a-kind gesture filled with love, meaning, and purpose—something that can never be replicated.</p>
      </div>
      <div className="mb-12">
        <h4>What You'll Receive</h4>
        <div>
          <div className="aspect-w-16 aspect-h-9 bg-gray-200 mb-4">
            <div className="flex items-center justify-center h-full">
              <img src={giftStory} alt="Gift Story" className="w-full h-full object-cover" />
            </div>
          </div>
          <p>
            Your personalized gift will be crafted based on your selected story and uploaded photo. 
            It will be a unique, high-quality item that captures the essence of your memorable experience.
          </p>
        </div>
      </div>
      {/* Main Form */}
      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Story Selection */}
        {
          !isAuthenticated ? (
            <div className="space-y-4">
              <h4>Sign in to continue</h4>
              <p>
                You must be signed in to continue.{' '}
                <a href="/login" className="underline">
                  Sign in now
                </a>
              </p>
            </div>
          ):(
            <div className="space-y-4">
          <h4>Select Your Story</h4>
          <div>
          <Select
              options={
                stories.map((story) => ({
                  value: story._id,
                  label: story.name,
                }))
              }
              isLoading={loading}
              loadingMessage={() => 'Loading stories...'}
              value={selectedStory}
              onChange={(selectedOption) => {
                setSelectedStory(selectedOption);
                fetchData(selectedOption?.value);
              }}
              placeholder="Search for your story..."
              isClearable
              isSearchable
              className="react-select-container"
              classNamePrefix="react-select"
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderRadius: 0,
                  border: 'none',
                  borderBottom: '1px solid black',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: 'black',
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? '#f3f4f6' : 'white',
                  color: 'black',
                }),
              }}
            />
            
            {!selectedStory && (
              <p className="mt-4 text-sm">
                No stories available?{' '}
                <a href="/start-your-story" className="underline">
                  Create one now
                </a>
              </p>
            )}
          </div>
          {selectedStory && story && (
            <div className="mt-4 p-4 border border-gray-200 max-h-96 overflow-y-auto ">
            <h4 className="text-center font-semibold text-gray-900">{story.name}</h4>

            <div dangerouslySetInnerHTML={{ __html: story.description }} />
          </div>
            )}
          {
              storyLoading && (
                <div className="flex items-center justify-center">
                  <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
                </div>
              )
            }
        </div>
          )
        }
       
        {/* Photo Upload */}
        <div className="space-y-4">
          <h4>Upload Your Photo</h4>
          {!imagePreview ? (
        <div className="border-2 border-dashed border-gray-300 rounded-lg p-12 text-center">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
            id="image-upload"
          />
          <label
            htmlFor="image-upload"
            className="cursor-pointer inline-flex items-center justify-center border border-transparent rounded-md transition-all bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
          >
            <BiUpload className="w-5 h-5 mr-2" />
            Upload Image
          </label>
        </div>
      ) : (
        <div className="space-y-4">
          <img src={imagePreview} alt="Uploaded" className="w-full object-cover" />
          <div className="flex justify-center space-x-4">
            
            <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
            id="replace-image-upload"
          />
            <label
            htmlFor="replace-image-upload"
            className="cursor-pointer inline-flex items-center justify-center border border-transparent rounded-md transition-all bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
          >
            <BiUpload className="w-5 h-5 mr-2" />
            Replace
          </label>
          </div>
        </div>
      )}
          
        </div>

        {/* Shipping Information */}
        <div className="space-y-4">
          <h4>Delivery Method</h4>
          <div>
            <div className="space-y-4">
              <label className="flex items-center space-x-3">
                <input
                  type="radio"
                  name="shipping"
                  value="shipping"
                  checked={shippingMethod === 'shipping'}
                  onChange={(e) => setShippingMethod(e.target.value)}
                  className=""
                />
                <p
                className="font-lato mb-0"
                >Ship to my address (+$10 shipping)</p>
              </label>
              <label className="flex items-center space-x-3">
                <input
                  type="radio"
                  name="shipping"
                  value="pickup"
                  checked={shippingMethod === 'pickup'}
                  onChange={(e) => setShippingMethod(e.target.value)}
                  className=""
                />
                <p
                className="font-lato mb-0"
                >Local pickup (166 Belmore Road, Riverwood)</p>
              </label>

              {shippingMethod === 'shipping' && (
                <div className="mt-4 space-y-4">
                  <input
                    type="text"
                    placeholder="Street Address"
                    className="w-full border-b py-2 focus:outline-none p-2 bg-white border-gray-400"
                    value={address.street}
                    onChange={(e) => setAddress({ ...address, street: e.target.value })}
                    required
                  />
                  <div className="grid grid-cols-3 gap-4">
                    <input
                      type="text"
                      placeholder="City"
                      className="w-full border-b py-2 focus:outline-none p-2 bg-white border-gray-400"
                      value={address.city}
                      onChange={(e) => setAddress({ ...address, city: e.target.value })}
                      required
                    />
                    <input
                      type="text"
                      placeholder="State"
                      className="w-full border-b py-2 focus:outline-none p-2 bg-white border-gray-400"
                      value={address.state}
                      onChange={(e) => setAddress({ ...address, state: e.target.value })}
                      required

                    /><input
                    type="text"
                    placeholder="Postal Code"
                    className="w-full border-b py-2 focus:outline-none p-2 bg-white border-gray-400"
                    value={address.postalCode}
                    onChange={(e) => setAddress({ ...address, postalCode: e.target.value })}
                    required
                  />
                  </div>
                  
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className={`w-full bg-black text-white py-3 px-6 transition-colors ${
            isFormValid ? '' : 'opacity-50 cursor-not-allowed'
          }`}
          disabled={!isFormValid || loading}
        >
          {
            loading ? (
              <i className="fa-solid fa-spinner animate-spin"></i>
            ) : 'Complete Purchase'
          }
        </button>
      </form>

      {/* Information Note */}
      <div className="mt-8 text-sm text-gray-600 text-center">
        <p>
          Note: You must have a completed story to make a purchase.
          Your gift will be linked to your selected story.
        </p>
      </div>
    </div>
  )
}
