import { useState } from 'react'
import { FiSave, FiX,FiEdit, FiSend } from 'react-icons/fi'


import MarkdownEditor from '../Markdown';
import Button from '../ui/Button';
import axios from 'axios';



export default function EditStory({ markdown,
    setIsEditing,
    setStory,

    storyId,
    story
}) {

  const [value, setValue] =useState(markdown);
  const [updating, setUpdating] = useState(false);
  const [isAIEditing, setIsAIEditing] = useState(false);
  const [whatToEdit, setWhatToEdit] = useState('');
  const [aiUpdating, setAIUpdating] = useState(false);
  const updateAPICall = async () => {
    setUpdating(true);
    const previousValue = story;
    setStory(
        (prev) => ({
            ...prev,
            description: value,
        }),
    );
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/api/story/${storyId}`,
            {
                description: value,
            },
            {
            
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          );
            if (response.data.success) {
                setIsEditing(false);
            }
            else{
                setStory((prev) => ({
                    ...prev,
                    description: previousValue,
                }));
            }
    } catch (error) {
        setStory((prev) => ({
            ...prev,
            description: previousValue,
        }));
    } finally {
        setUpdating(false);
    }
  }
  const updateAPICallAI = async (index) => {
    setAIUpdating(true);
    const toEditText = whatToEdit;
    setWhatToEdit(null);
    try {
      let response;
     
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/story/update-report-ai`
          , {
          whatToEdit: whatToEdit,
         
          previousResponse: value,
        },
        {
            
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      
      );
      

      if (response.data.success) {
        // now update the markdown
        setValue(response.data.data.gptResponse);
        setAIUpdating(false);
        setIsAIEditing(false);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      setAIUpdating(false);
    }
  };
  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex justify-end items-center p-4">
        <div className="flex space-x-2">
        {
          isAIEditing ? (
          <>
            <Button
              onClick={() => {
                updateAPICallAI();
              }}
              loading={aiUpdating}
              disabled={!whatToEdit || aiUpdating}
              className="flex items-center px-3 py-2 bg-black text-white self-end"
            >
              <FiSend className="mr-2" /> Send
            </Button>
            <Button
              onClick={() => {
                // setIsEditing(false)
                setIsAIEditing(false);
              }}
              disabled={aiUpdating}
              className="flex items-center px-3 py-2 bg-darkergray text-white"
            >
              <FiX className="mr-2" /> Cancel
            </Button>
          </>
        ) :(<>
          <Button
              onClick={() => setIsAIEditing(true)}
              disabled={updating}
              className="flex items-center px-3 py-2 bg-black text-white"
            >
              <FiEdit className="mr-2" /> AI
            </Button>
        <Button
                onClick={() => {
                  updateAPICall();
                }}
                loading={updating}
                className="flex items-center px-3 py-2 bg-black text-white"
              >
                <FiSave className="mr-2" /> Save
              </Button>
              <Button
                onClick={() => setIsEditing(false)}
                className="flex items-center px-3 py-2 bg-darkergray text-white"
              >
                <FiX className="mr-2" /> Cancel
              </Button></>)
        }
        
        </div>
      </div>
      {isAIEditing && (
        <div className="w-full mt-2">
          <textarea
            disabled={aiUpdating}
            className="border border-mediumgray w-full p-2"
            rows={5}
            placeholder="What to edit? Please provide details."
            value={whatToEdit}
            onChange={(e) => setWhatToEdit(e.target.value)}
          ></textarea>
        </div>
      )}
      <MarkdownEditor
          value={value}
            setValue={setValue}
          />
      
    </div>
  )
}